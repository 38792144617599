import { memo, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { components } from 'react-select';
import './dropdown.scss'
import CustomTooltip from '../Tooltip/CustomTooltip';
import { slice } from '../../../Utils/stringUtils';

const Dropdown = memo(({ options, isDisabled, isClearable, isLoading, isSearchable, name, isRtl, value, onChange, label, placeholder, isRequired, ...props }) => {

    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (name) {
            const foundOption = options.find(option => option.value == name);  // eslint-disable-line eqeqeq

            if (foundOption) {
                setSelectedOption(foundOption);
            }
        }
    }, [name, options]);

    const handleChange = (selectedOption) => {

        if (onChange) {
            onChange(selectedOption ? selectedOption.value : '');
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: state.isFocused ? null : provided.boxShadow,
            borderColor: state.isFocused ? 'var(--border-light)' : provided.borderColor,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--secondary)' : provided.borderColor,
            }
        })
    };

    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <div>
                    <CustomTooltip text={props?.data?.label || ''}>
                        <strong style={{ textTransform: "uppercase" }}>{slice(props?.data?.label || '', 20)}</strong>
                    </CustomTooltip>
                    <CustomTooltip text={props?.data?.description || ''}>
                        <small>{slice(props?.data?.description || '', 20)}</small>
                    </CustomTooltip>
                </div>
            </components.Option>
        );
    };

    return (
        <>
            <Form.Label className={isRequired ? "required" : ""} aria-label={label}>{label}</Form.Label>
            <Select
                className="basic-single mb-3"
                classNamePrefix="select"
                defaultValue={selectedOption}
                isDisabled={isDisabled}
                isLoading={isLoading}
                cus
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                components={{ Option: CustomOption }}
                name={name}
                menuPosition="fixed"
                options={options}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                value={options?.filter(({ value }) => value == name)}
                onChange={handleChange}
                placeholder={`Select ${placeholder || "Value"}`}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--border-light)',
                        primary: 'var(--primary)',
                    },
                })}
                {...props}
            />
        </>
    );
});

export default Dropdown;
